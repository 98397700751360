.copy {
  cursor: pointer;
  background: url('../../ImageAssets/copy2clipboard_light.svg') no-repeat
    center/contain;
  width: 18px;
  height: 18px;
  border: none;
  padding: 0;
  flex-shrink: 0;
}

.copy:disabled {
  background: url('../../ImageAssets/copied_light.svg') no-repeat center/contain;
  cursor: auto;
}

:global(html.dark) .copy {
  background: url('../../ImageAssets/copy2clipboard_dark.svg') no-repeat
    center/contain;
}

:global(html.dark) .copy:disabled {
  background: url('../../ImageAssets/copied_dark.svg') no-repeat center/contain;
}
