.container {
  composes: container from '../Results/Results.module.css';
}

.title {
  composes: title from '../Results/Results.module.css';
}

.text {
  composes: text from '../Results/Results.module.css';
}

.wrapper {
  composes: wrapper from '../Results/Results.module.css';
  justify-content: space-between;
}

.accountsContainer {
  display: flex;
  flex-direction: column;
  max-width: 520px;
  width: 80%;
  gap: 10px;
}

.titleWrapper {
  composes: titleWrapper from '../Results/Results.module.css';
}
