.container {
  composes: container from '../Results/Results.module.css';
  margin-top: 20px;
}

.title {
  composes: title from '../Results/Results.module.css';
  display: flex;
  min-width: 105px;
  align-self: flex-start;
  margin-top: 15px;
}

.text {
  composes: text from '../Results/Results.module.css';
  word-break: normal;
}

.wrapper {
  composes: wrapper from '../Results/Results.module.css';
  width: 620px;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 4px;
}

.window {
  composes: cardContainer from '../Card/Card.module.css';
  background-color: var(--light-green);
}

.windowContents {
  composes: cardContents from '../Card/Card.module.css';
  line-height: 25px;
}

.controlBtn {
  composes: expand from '../Card/Card.module.css';
}

.guide {
  font-size: 16px;
  letter-spacing: 0.11px;
  line-height: 25px;
  margin: 0;
}

.extensionWrapper {
  display: flex;
  gap: 30px;
  margin: 26px 0 37px;
}

.linkToGuide {
  color: var(--web3-name);
  text-decoration: underline;
}

.chromeExtension {
  height: 66px;
  max-width: 251px;
  width: 40%;
  background: url('../../ImageAssets/chrome_icon.svg') no-repeat center left 10%/20%;
  border: 1px solid rgb(var(--extension-border-rgb) / 50%);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: black;
  text-decoration: none;
}

.firefoxExtension {
  composes: chromeExtension;
  background-image: url('../../ImageAssets/firefox_icon.svg');
}

.linkTextSmall {
  composes: text;
  font-size: 11px;
  line-height: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-left: 50px;
  color: var(--text);
}

.linkTextBig {
  composes: text;
  font-size: 16px;
}

@media (width <= 580px) {
  .linkTextSmall {
    margin-left: 40px;
  }

  .linkTextBig {
    font-size: 13px;
    line-height: 14px;
  }
}

.list {
  font-size: 16px;
  letter-spacing: 0.1px;
  list-style-type: '-';
  padding-left: 16px;
}

.listItem {
  line-height: 25px;
  margin: 0;
  padding-left: 8px;
}

.listItem::marker {
  font-weight: normal;
}

.onchainGuide {
  composes: guide;
  margin-bottom: 0.5rem;
}
