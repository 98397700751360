.container {
  composes: container from '../View/View.module.css';
}

.headingContainer {
  composes: headingContainer from '../View/View.module.css';
  height: 80px;
}

.termsHeading {
  composes: headingContent from '../View/View.module.css';
  font-size: 24px;
  font-weight: 300;
  letter-spacing: 0.17px;
}

.termsContainer {
  composes: mainContainer from '../View/View.module.css';
}

.terms {
  composes: mainContent from '../View/View.module.css';
  font-size: 14px;
  letter-spacing: 0.1px;
  line-height: 16px;
}

.spacedHeading {
  margin-top: 40px;
}

.bulletList {
  list-style-type: circle;
}

.anchor {
  color: var(--web3-name);
}
