.container {
  composes: container from '../Results/Results.module.css';
  margin-top: 20px;
}

.title {
  composes: title from '../Results/Results.module.css';
  display: flex;
  min-width: 105px;
}

.text {
  composes: text from '../Results/Results.module.css';
  word-break: normal;
}

.wrapper {
  display: flex;
  flex-direction: column;
  max-width: 620px;
  width: 90%;
}

.anchor {
  display: inline-flex;
  width: 80%;
  color: var(--web3-name);
  word-break: break-all;
  text-decoration: none;
}
