.container {
  composes: container from '../View/View.module.css';
}

.headingContainer {
  composes: headingContainer from '../View/View.module.css';
  height: 80px;
}

.privacyHeading {
  composes: headingContent from '../View/View.module.css';
  font-size: 24px;
  font-weight: 300;
  letter-spacing: 0.17px;
}

.privacyContainer {
  composes: mainContainer from '../View/View.module.css';
}

.privacy {
  composes: mainContent from '../View/View.module.css';
  font-size: 14px;
  letter-spacing: 0.1px;
  line-height: 16px;
}

li::marker {
  font-size: 16px;
  font-weight: bolder;
}

.spacedHeading {
  composes: spacedHeading from '../Terms/Terms.module.css';
}

.bulletList {
  composes: bulletList from '../Terms/Terms.module.css';
}

.anchor {
  composes: anchor from '../Terms/Terms.module.css';
}
