.header {
  background-color: var(--body);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100vw;
  position: relative;
}

.logo {
  width: 70px;
  height: 30px;
  margin-block: 15px;
  margin-inline-start: calc((100% - min(90%, 740px)) / 2);
  background: url('../../ImageAssets/w3n_logo_light.svg') no-repeat;
}

:global(html.dark) .logo {
  background: url('../../ImageAssets/w3n_logo_dark.svg') no-repeat center left;
}

.headingTextContainer {
  background-color: var(--header-secondary);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-block: 0.25rem;
  border-block-end: 10px solid rgb(var(--separator-rgb) / 25%);
}

.text {
  display: flex;
  align-items: center;
  max-width: 740px;
  width: 90%;
  color: var(--header-text);
  font-size: 16px;
  letter-spacing: 0.11px;
  line-height: 17px;
  height: 34px;
}
