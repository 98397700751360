.container {
  composes: container from '../View/View.module.css';
}

.search {
  composes: headingContainer from '../View/View.module.css';
  height: 144px;
}

.searchBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 740px;
  width: 90%;
  height: 32px;
  border-radius: 16px;
  background-color: var(--search-bar);
  box-shadow: inset 0 2px 2px 0 rgb(var(--black-rgb) / 35%);
  padding-left: 10px;
}

.button {
  background: url('../../ImageAssets/magnifier.svg') no-repeat;
  width: 24px;
  height: 24px;
  margin-right: 5px;
  border: none;
  cursor: pointer;
}

.input {
  width: 85%;
  color: black;
  font-size: 14px;
  letter-spacing: 0.26px;
  line-height: 22px;
  background: transparent;
  border: none;
}

.input:focus {
  outline: none;
}

.infoText {
  color: var(--text);
  font-size: 16px;
  letter-spacing: 0.11px;
  line-height: 17px;
  max-width: 740px;
  width: 90%;
}

.infoTextAddress {
  color: var(--text);
  font-size: 14px;
  letter-spacing: 0.1px;
  line-height: 16px;
  text-align: right;
  opacity: 0.8;
  padding-right: 5px;
}

.main {
  composes: mainContainer from '../View/View.module.css';
}

:global(html.dark) .main {
  background: linear-gradient(
    180deg,
    rgb(var(--gradient-2-1-rgb) / 68%) 0,
    rgb(var(--gradient-2-2-rgb) / 60%) 60px,
    rgb(var(--gradient-2-3-rgb) / 0%) 185px
  );
  border-top: 10px rgb(var(--gradient-2-1-rgb) / 50%) solid;
}

.results {
  composes: mainContent from '../View/View.module.css';
  padding: 0;
}

.noWeb3name {
  composes: results;
  margin-top: 40px;
}

.title {
  width: 95px;
  margin-top: 10px;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.1px;
  line-height: 22px;
  text-align: left;
}

.infoContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  max-width: 740px;
  width: 87%;
}

.modeContainer {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  gap: 10px;
  position: relative;
  font-size: 14px;
  letter-spacing: 0.1px;
  line-height: 16px;
  text-align: center;
  margin-bottom: 50px;
  margin-top: -10px;
}

.availableActiveMode {
  position: absolute;
  height: 35px;
  width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom-left-radius: 13px;
  border-bottom-right-radius: 13px;
  background-color: var(--header-secondary);
  box-shadow: -3px 6px 8px 0 rgb(var(--black-rgb) / 25%);
  top: 0;
  right: 160px;
  color: var(--header-text);
  z-index: 10;
}

.availableInactiveMode {
  composes: availableActiveMode;
  background-color: var(--inactive-tab);
  color: var(--inactive-text);
  opacity: 0.5;
  z-index: 0;
}

.takenActiveMode {
  position: absolute;
  height: 35px;
  width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom-left-radius: 13px;
  border-bottom-right-radius: 13px;
  background-color: var(--header-secondary);
  box-shadow: -3px 6px 8px 0 rgb(var(--black-rgb) / 25%);
  top: 0;
  right: 0;
  color: var(--header-text);
  z-index: 10;
}

.takenInactiveMode {
  composes: takenActiveMode;
  background-color: var(--inactive-tab);
  color: var(--inactive-text);
  opacity: 0.5;
  z-index: 0;
}
