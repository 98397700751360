.endpointWrapper {
  composes: cardContainer from '../Card/Card.module.css';
}

.type {
  overflow-wrap: break-word;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0;
  line-height: 17px;
  margin: 5px 0 5px 22px;
  color: #fff;
}

.endpoint {
  composes: cardContents from '../Card/Card.module.css';
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 13px 18px;
}

@media (width <= 700px) {
  .endpoint {
    flex-direction: column;
    gap: 10px;
  }
}

.fetched {
  composes: endpoint;
  flex-direction: column;
  align-items: flex-start;
  padding: 18px;
}

.urlContainer {
  display: flex;
  justify-content: flex-start;
  gap: 5px;
  margin-left: 8px;
  margin-right: 10px;
  width: 70%;
}

.fetchedUrlContainer {
  composes: urlContainer;
  width: 100%;
  max-width: none;
  align-items: flex-start;
}

.url {
  display: block;
  font-family: Overpass, sans-serif;
  font-size: 16px;
  letter-spacing: 0.11px;
  line-height: 17px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 0.11px;
  line-height: 17px;
  color: var(--fetch-button-text);
  border: 3px solid #df8601;
  border-radius: 15px;
  background-color: var(--fetch-button);
  position: relative;
  width: 124px;
  height: 28px;
}

.transferButton {
  composes: button;
  background: url('../../ImageAssets/not_ok.svg') no-repeat center left 0.25rem/1rem;
}

.fetch {
  composes: button;
}

.close {
  composes: button;
  align-self: flex-end;
}

.button:disabled {
  border: 3px solid rgba(var(--fetch-button-border-disabled-rgb) / 50%);
  color: rgba(var(--fetch-button-text-disabled-rgb) / 50%);
}

.loader {
  composes: spinner from '../Spinner/Spinner.module.css';
  position: absolute;
  right: 2px;
  height: 18px;
  width: 18px;
}

.transfer {
  inline-size: 100%;
  margin: 0.5rem;
  padding-inline-start: 1.25rem;
  background: url('../../ImageAssets/not_ok.svg') no-repeat top left/1rem;
}

.seperator {
  border: 1px dashed var(--web3-name);
  margin-top: 18px;
  width: 100%;
  opacity: 0.5;
}

.didDocument {
  composes: container from '../Results/Results.module.css';
  margin-top: 20px;
  justify-content: flex-start;
}

.wrapper {
  composes: wrapper from '../Results/Results.module.css';
}

.endpoints {
  composes: wrapper;
  width: 620px;
}

.text {
  composes: text from '../Results/Results.module.css';
}

.title {
  composes: title from '../Results/Results.module.css';
}

.titleWrapper {
  composes: titleWrapper from '../Results/Results.module.css';
  align-self: start;
}

.credentials {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

.result {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  margin-top: 20px;
}

.credential {
  composes: result;
}

.credential:not(:last-child) {
  border-bottom: 1px solid rgba(var(--gradient-1-2-rgb) / 30%);
}

.error {
  composes: result;
}
