.container {
  composes: container from '../Results/Results.module.css';
  margin: 0;
}

.title {
  composes: title from '../Results/Results.module.css';
  min-width: 105px;
  align-self: center;
}

.wrapper {
  composes: wrapper from '../Results/Results.module.css';
  height: 100%;
  align-items: center;
}

.text {
  composes: text from '../Results/Results.module.css';
  font-size: 24px;
  letter-spacing: 0.17px;
  line-height: 26px;
}

.italic {
  composes: text;
  font-style: italic;
}

.error {
  composes: text from '../Results/Results.module.css';
}
