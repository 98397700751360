.container {
  composes: container from '../Results/Results.module.css';
  align-items: center;
}

.title {
  composes: title from '../Results/Results.module.css';
}

.text {
  composes: text from '../Results/Results.module.css';
}

.error {
  composes: text;
  padding-right: 24px;
  font-style: italic;
  font-weight: bold;
  background: url('../../ImageAssets/attention.svg') no-repeat right;
}

.wrapper {
  composes: wrapper from '../Results/Results.module.css';
  align-items: center;
}

.titleWrapper {
  composes: titleWrapper from '../Results/Results.module.css';
  align-items: center;
}
