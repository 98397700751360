.container {
  padding-left: 10px;
}

.heading {
  font-size: 20px;
  margin: 0 0 20px;
}

.definitions {
  margin: 0;
}

.definition {
  display: flex;
  width: 90%;
  gap: 20px;
  margin: 0 0 20px;
}

.title {
  width: 20%;
  min-width: 90px;
  font-family: Overpass, sans-serif;
  font-size: 16px;
  letter-spacing: 0.11px;
  line-height: 17px;
  text-align: left;
  word-break: normal;
  align-self: center;
}

.description {
  display: block;
  font-size: 16px;
  letter-spacing: 0.11px;
  line-height: 17px;
  word-break: break-word;
  margin: 0;
}

.valid {
  margin: 0;
  width: 24px;
  height: 24px;
}

.spinner {
  composes: spinner from '../Spinner/Spinner.module.css';
  height: 16px;
  width: 16px;
  display: inline-block;
}

.anchor {
  text-decoration: none;
  color: var(--web3-name);
}
