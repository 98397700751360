.definitions {
  composes: definitions from '../CredentialDetails/CredentialDetails.module.css';
}

.definition {
  composes: definition from '../CredentialDetails/CredentialDetails.module.css';
}

.title {
  composes: title from '../CredentialDetails/CredentialDetails.module.css';
}

.description {
  composes: description from '../CredentialDetails/CredentialDetails.module.css';
}

.invalid {
  composes: valid from '../CredentialDetails/CredentialDetails.module.css';
}
