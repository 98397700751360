.container {
  height: 195px;
  width: 100vw;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;
  margin-top: 45px;
}

.contents {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 740px;
  width: 90%;
  gap: 45px;
}

.title {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.11px;
  line-height: 17px;
}

.text {
  word-break: normal;
  font-size: 16px;
  letter-spacing: 0.11px;
  line-height: 17px;
}

.anchor {
  color: var(--web3-name);
  text-decoration: none;
}
