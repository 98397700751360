.footer {
  width: 100%;
  display: flex;
  place-content: center;
  min-height: 34px;
  background: var(--footer);
  border-top: 1px solid var(--take-tour);
  margin-top: auto;
}

.content {
  max-width: 766px;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
}

@media (width <= 700px) {
  .content {
    flex-direction: column;
    width: 100%;
  }
}

.navMenu {
  display: flex;
  align-items: center;
  gap: 10px;
}

@media (width <= 700px) {
  .navMenu {
    width: 100%;
    display: flex;
    place-content: center;
    padding: 7px 0;
    border-bottom: 1px solid rgb(var(--link-disabled-rgb) / 30%);
  }
}

.navLink {
  color: var(--web3-name);
  text-decoration: none;
  line-height: 20px;
}

.navLinkActive {
  composes: navLink;
  opacity: 0.5;
}

.copyright {
  color: var(--text);
  padding: 7px 90px 0 0;
  line-height: 20px;
  font-size: 10px;
  margin: 0;
  background: url('../../ImageAssets/built_on_kilt_light.svg') no-repeat center
    right/25%;
}

:global(html.dark) .copyright {
  background: url('../../ImageAssets/built_on_KILT_dark.svg') no-repeat center
    right/25%;
}

@media (width <= 700px) {
  .copyright {
    margin: 0 0 7px;
  }
}
