.container {
  position: fixed;
  inset: 0;
  background-color: rgb(var(--color-shadow-rgb) / 10%);
  backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 30;
}

.modal {
  max-width: 380px;
  width: 80%;
  background-color: var(--modal-background);
  box-shadow: 0 6px 8px 0 rgb(var(--color-shadow-rgb) / 25%);
  border: 2px solid var(--modal-border);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  gap: 20px;
}

.heading {
  color: var(--text);
  font-size: 24px;
  letter-spacing: 0.1px;
  line-height: 17px;
  font-weight: 300;
  margin-top: 30px;
  display: flex;
  justify-content: center;
}

.text {
  color: var(--text);
  font-size: 16px;
  letter-spacing: 0.1px;
  line-height: 17px;
  text-align: center;
  width: 70%;
}

.web3name {
  composes: text;
  font-size: 24px;
  line-height: 26px;
}

.status {
  margin-top: 10px;
}

.btn {
  display: inline-flex;
  color: var(--button-primary-text);
  margin: 24px 0;
  width: 80%;
  height: 30px;
  border-radius: 15px;
  border: 3px solid var(--button-primary-border);
  font-size: 16px;
  line-height: 17px;
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  background: var(--button-primary-background);
}

.btn:disabled {
  color: rgb(var(--modal-btn-disabled-rgb) / 50%);
  border: 3px solid rgb(var(--modal-btn-disabled-rgb) / 50%);
  cursor: none;
}

.step {
  color: white;
  position: absolute;
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 16px;
}

.successIcon {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  background:
    url('../../ImageAssets/ok.svg') no-repeat center/80%,
    #fff;
}

.errorIcon {
  composes: successIcon;
  background:
    url('../../ImageAssets/not_ok.svg') no-repeat center/80%,
    #fff;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.spinner {
  composes: spinner from '../Spinner/Spinner.module.css';
  width: 70px;
  height: 70px;
}
