.container {
  min-height: 100vh;
  max-width: 100vw;
  background-color: var(--body);
  display: flex;
  flex-direction: column;
  overflow: hidden auto;
  align-items: center;
  justify-content: center;
  font-family: Overpass, sans-serif;
  color: var(--text);
  position: relative;
}
