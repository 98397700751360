.container {
  composes: container from '../Results/Results.module.css';
  margin-top: 20px;
}

.title {
  composes: title from '../Results/Results.module.css';
  display: flex;
  min-width: 105px;
  align-self: start;
  margin-bottom: 2px;
}

.text {
  color: var(--text);
  font-size: 16px;
  letter-spacing: 0.11px;
  line-height: 17px;
}

.topText {
  composes: text;
  margin: 0;
}

.bottom {
  display: flex;
  justify-content: space-between;
  margin: 44px 0 0;
}

.bottomText {
  composes: text;
  margin: 0;
}

.anchor {
  color: var(--web3-name);
}

.checkoutLegal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-block-start: 0.5rem;
}

.wrapper {
  composes: wrapper from '../Results/Results.module.css';
  width: 620px;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 4px;
}

.claimContainer {
  composes: cardContainer from '../Card/Card.module.css';
  width: 100%;
  position: relative;
  min-height: 36px;
  margin-top: 20px;
  justify-content: center;
}

.controlBtn {
  composes: expand from '../Card/Card.module.css';
}

.claimContents {
  composes: cardContents from '../Card/Card.module.css';
}

.steps {
  margin: 0;
  padding-left: 16px;
}

.step {
  composes: text;
}

.step::marker {
  font-weight: normal;
}

.selectAccount {
  composes: step;
  margin-bottom: 64px;
}

.stepsHeading {
  composes: step;
  margin: 0;
}

.btn {
  display: inline-block;
  padding-block: 4px;
  box-sizing: border-box;
  height: 28px;
  max-width: 320px;
  width: 80%;
  border: 3px solid var(--button-primary-border);
  border-radius: 15px;
  background-color: var(--button-primary-background);
  color: var(--button-primary-text);
  font-family: Overpass, sans-serif;
  font-size: 16px;
  letter-spacing: 0.11px;
  line-height: 17px;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  position: relative;
  text-decoration: none;
}

.btn + .btn {
  margin-top: 0.5rem;
}

.btn:disabled,
.btn[aria-disabled='true'],
.loaded {
  color: rgb(var(--modal-btn-disabled-rgb) / 50%);
  border: 3px solid rgb(var(--modal-btn-disabled-rgb) / 50%);
  cursor: not-allowed;
}

@media (width <= 420px) {
  .btn {
    font-size: 12px;
  }

  .step {
    font-size: 14px;
  }
}

.spinner {
  composes: spinner from '../Spinner/Spinner.module.css';
  display: block;
  width: 1rem;
  height: 1rem;
  position: absolute;
  right: 0.25rem;
  top: 0.2rem;
}

.loaded {
  composes: btn;
  margin: 0;
  background: url('../../ImageAssets/ok.svg') 99% center/1rem no-repeat;
}

.select {
  position: absolute;
  z-index: 1;
  min-height: 28px;
  max-width: 320px;
  width: 80%;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  background:
    url('../../ImageAssets/open_triangle.svg') no-repeat top 11px right 10px/9px
      6px,
    var(--select);
  color: var(--select-text);
}

.select[aria-disabled='true'] {
  opacity: 0.4;
  pointer-events: none;
}

.selectShow {
  composes: select;
  background:
    url('../../ImageAssets/close_triangle.svg') no-repeat top 11px right 10px/9px
      6px,
    var(--select);
}

.options {
  background: var(--options-background);
  color: var(--text);
  border-radius: 5px 5px 15px 15px;
  display: flex;
  flex-direction: column;
  list-style-position: inside;
  padding-left: 0;
  margin: 0 3px 3px;
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.selectText {
  composes: ellipsis;
  padding-left: 17px;
  margin-top: 5px;
  font-size: 16px;
  letter-spacing: 0.11px;
  line-height: 17px;
  width: 90%;
  text-align: left;
  height: 20px;
}

.option {
  display: flex;
  align-items: center;
  height: 30px;
  list-style-type: none;
  margin: 0;
  padding-left: 14px;
}

.option:last-child {
  border-radius: 0 0 15px 15px;
}

.option:not([aria-disabled='true']):hover {
  background-color: rgb(223 134 1 /50%);
}

.option[aria-disabled='true'] {
  cursor: not-allowed;
  font-style: italic;
}

.optionText {
  composes: ellipsis;
  width: 90%;
  text-align: left;
  font-size: 16px;
  letter-spacing: 0.11px;
  line-height: 17px;
}

.error {
  composes: claimContents;
  text-align: center;
  color: red;
}
