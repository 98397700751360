.button {
  background: url('../../ImageAssets/tool_tip_light.svg') no-repeat center/contain;
  position: relative;
  width: 16px;
  height: 16px;
  border: none;
  cursor: pointer;
}

.wrapper {
  position: absolute;
  top: 30px;
  right: -15px;
  display: flex;
  width: 440px;
  z-index: 20;
}

.right {
  composes: wrapper;
  left: -16px;
  top: 20px;
}

@media (width <= 480px) {
  .wrapper {
    width: 200px;
  }
}

.text {
  position: relative;
  padding: 15px;
  margin: 1em 0 0;
  border: 2px solid var(--web3-name);
  background-color: var(--modal-bg);
  color: var(--text);
  box-shadow: 0 6px 8px 0 rgba(var(--black-rgb) / 25%);
  border-radius: 8px;
  font-size: 16px;
  letter-spacing: 0.11px;
  line-height: 22px;
  text-align: left;
}

.text::before {
  content: '';
  position: absolute;
  border-style: solid;
  border-color: var(--web3-name) transparent;
  display: block;
  width: 0;
  inset: -12px 10px auto auto;
  border-width: 0 13px 12px;
}

.right .text::before {
  left: 10px;
  right: auto;
}

.text::after {
  content: '';
  position: absolute;
  border-style: solid;
  border-color: var(--modal-bg) transparent;
  display: block;
  width: 0;
  inset: -8px 11px auto auto;
  border-width: 0 12px 10px;
}

.right .text::after {
  left: 11px;
  right: auto;
}
