.container {
  composes: container from '../View/View.module.css';
}

.headingContainer {
  composes: headingContainer from '../View/View.module.css';
  height: 80px;
}

.heading {
  composes: headingContent from '../View/View.module.css';
  font-size: 24px;
  font-weight: 300;
  letter-spacing: 0.17px;
}

.imprintContainer {
  composes: mainContainer from '../View/View.module.css';
}

.imprint {
  composes: mainContent from '../View/View.module.css';
  letter-spacing: 0.1px;
  line-height: 22px;
  font-size: 14px;
}

.imprintSection {
  margin: 16px 0 0;
}

.imprintLine {
  margin: 0;
}

.anchor {
  text-decoration: none;
  color: var(--web3-name);
}
